import {
  fetchFeedbackList,
  fetchNoticeAiList,
  fetchXIANXIACANGUANAiList,
  fetchMIANSHIFANKUIAiList,
} from './api'

const TabEntity = {
  AI: 1,
  Apply: 2,
  Interview: 3,
}

const state = {
  isCollapse: false, // 是否折叠
  detailEnums: {}, // 详情中的枚举
  detailCount: {}, // 用工需求详情下面的统计数据
  aiParams: {}, // AI评估筛选条件
  applyParams: {}, // 报名筛选条件
  interviewParams: {}, // 面试筛选条件
  feedbackParams: {},
  feedbackData: {},
  feedbackLoading: false,
  selectedRowKeys: [],
  employmentInfo: {},
  aiInterviewParams: {},
  MIANSHIFANKUIData: {},
  XIANXIACANGUANData: {},
}

const getters = {
  getIsCollapse: (state) => state.isCollapse,
  getDetailEnums: (state) => state.detailEnums,
  getDetailCount: (state) => state.detailCount,
  getFeedbackData: (state) => state.feedbackData,
  feedbackLoading: (state) => state.feedbackLoading,
  getSelectedRowKeys: (state) => state.selectedRowKeys,
  getAiParams: (state) => state.aiParams,
  getApplyParams: (state) => state.applyParams,
  getInterviewParams: (state) => state.interviewParams,
  getAiInterviewParams: (state) => state.aiInterviewParams,
  getEmploymentInfo: (state) => state.employmentInfo,
  getMIANSHIFANKUIData: (state) => state.MIANSHIFANKUIData,
  getXIANXIACANGUANData: (state) => state.XIANXIACANGUANData,
}

const mutations = {
  setIsCollapse: (state, value) => {
    state.isCollapse = value
  },
  setDetailEnums: (state, values) => {
    state.detailEnums = values
  },
  setDetailCount: (state, detail) => {
    state.detailCount = detail
  },
  setAiParams: (state, params) => {
    if (params?.rest_type) {
      state.aiParams = {
        ...params,
        rest_type: undefined,
      }
    } else {
      state.aiParams = {
        ...params,
      }
    }
  },
  setApplyParams: (state, params) => {
    if (params?.rest_type) {
      state.applyParams = {
        ...params,
        rest_type: undefined,
      }
    } else {
      state.applyParams = {
        ...params,
      }
    }
  },
  setInterviewParams: (state, params) => {
    if (params?.rest_type) {
      state.interviewParams = {
        ...params,
        rest_type: undefined,
      }
    } else {
      state.interviewParams = {
        ...params,
      }
    }
  },
  setAiInterviewParams: (state, params) => {
    if (params?.rest_type) {
      state.aiInterviewParams = {
        ...params,
        rest_type: undefined,
      }
    } else {
      state.aiInterviewParams = {
        ...params,
      }
    }
  },
  clearCachedParams: (state) => {
    state.aiInterviewParams = {}
    state.interviewParams = {}
    state.applyParams = {}
    state.aiParams = {}
  },
  setFeedbackData: (state, data) => {
    state.feedbackData = data
  },
  setFeedbackLoading: (state, value) => {
    state.feedbackLoading = value
  },
  setSelectedRowKeys: (state, value) => {
    state.selectedRowKeys = value
  },
  setEmploymentInfo: (state, value) => {
    state.employmentInfo = value
  },
  setMIANSHIFANKUIData: (state, value) => {
    state.MIANSHIFANKUIData = value
  },
  setXIANXIACANGUANData: (state, value) => {
    state.XIANXIACANGUANData = value
  },
}

const actions = {
  clearCachedParams: async ({ commit }) => {
    await commit('clearCachedParams')
  },
  queryFeedbackList: async ({ commit, state }, values) => {
    if (values.type === TabEntity.AI) {
      await commit('setAiParams', { ...values })
    } else if (values.type === TabEntity.Apply) {
      await commit('setApplyParams', { ...values })
    } else if (values.type === TabEntity.Interview) {
      await commit('setInterviewParams', { ...values })
    }
    commit('setFeedbackLoading', true)
    const api = values.sub_type === 4 ? fetchNoticeAiList : fetchFeedbackList
    const data = await api(values)
    commit('setFeedbackData', data)
    commit('setFeedbackLoading', false)
  },
  // queryAiNoticeList: async ({ commit, state }, values) => {
  //   if (values.type === TabEntity.AI) {
  //     await commit('setAiParams', { ...values })
  //   } else if (values.type === TabEntity.Apply) {
  //     await commit('setApplyParams', { ...values })
  //   } else if (values.type === TabEntity.Interview) {
  //     await commit('setInterviewParams', { ...values })
  //   }
  //   commit('setFeedbackLoading', true)
  //   const data = await fetchNoticeAiList(values)
  //   commit('setFeedbackData', data)
  //   commit('setFeedbackLoading', false)
  // },
  queryMIANSHIFANKUIList: async ({ commit, state }, values) => {
    await commit('setAiInterviewParams', { ...values })
    commit('setFeedbackLoading', true)
    const data = await fetchMIANSHIFANKUIAiList(values)
    console.log('queryMIANSHIFANKUIList', data)
    commit('setMIANSHIFANKUIData', data)
    commit('setFeedbackLoading', false)
  },
  queryXIANXIACANGUANList: async ({ commit, state }, values) => {
    commit('setFeedbackLoading', true)
    await commit('setAiInterviewParams', { ...values })

    const data = await fetchXIANXIACANGUANAiList(values)
    commit('setXIANXIACANGUANData', data)
    commit('setFeedbackLoading', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
