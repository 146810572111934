import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import AltihexUser from '../layoutUser'
import AltihexNews from '../layoutNews'
import { newsPathList } from './helper'
import './index.less'
export default defineComponent({
  components: {
    AltihexUser,
    AltihexNews,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:collapsed'],
  setup(p, { emit }) {
    const route = useRoute();
    console.log('🚀 ~ setup ~ route:', route.path)
    return () => (
      <a-layout-header class='altihex-header' height='54'>
        <span
          class='altihex-header__leftOptions'
          onClick={() => emit('update:collapsed', !p.collapsed)}>
          {!p.collapsed ? <menuFoldOutlined /> : <menuUnfoldOutlined />}
        </span>
        <div class='altihex-header__rightOptions'>
          {newsPathList?.includes(route.path) ? <altihex-news /> : ''}
          <altihex-user></altihex-user>
        </div>
      </a-layout-header>
    )
  },
})
