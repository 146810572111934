const ENV = 't1'

export default {
  developmentEnv: ENV,
  sysAclName: 'lms',
  projectName: '劳务公司后台',
  api: {
    successStatusCode: [200],
    successDataCodeField: 'code',
    successDataCodeValue: [200],
    getDataField: 'data',
    baseURL(): string {
      const env = process.env.NODE_ENV === 'development' ? `/${ENV}` : ''
      console.log('前缀------', env)
      return `${env}/api/v1/h5/workforce/supplier/api`
    },
  },
}
