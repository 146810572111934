import { request } from '@request'

export default {
  // 查询排班
  queryScheduleList: (params = {}) =>
    request({
      url: '/schedule/page',
      method: 'get',
      params,
    }),

  // 导出排班
  exportScheduleList: (params = {}) =>
    request({
      url: '/schedule/export',
      method: 'get',
      params,
    }),

  // 查询对账列表
  queryReconciliationList: (params = {}) =>
    request({
      url: '/reconciliation/page',
      method: 'get',
      params,
    }),

  // 查看对账详情
  queryReconciliationDetailList: (params = {}) =>
    request({
      url: '/reconciliation/detail/page',
      method: 'get',
      params,
    }),

  // 提交申诉
  submitAppeal: (data = {}) =>
    request({
      url: '/reconciliation/appeal/submit',
      method: 'post',
      data,
    }),

  // 取消申诉
  cancelAppeal: (data = {}) =>
    request({
      url: '/reconciliation/appeal/cancel',
      method: 'post',
      data,
    }),

  // 重新申诉
  resubmitAppeal: (data = {}) =>
    request({
      url: '/reconciliation/appeal/resubmit',
      method: 'post',
      data,
    }),

  // 导出申诉列表
  exportReconciliation: (params = {}) =>
    request({
      url: '/reconciliation/export',
      method: 'get',
      params,
    }),

  // 对账申请
  applyForReconciliation: (data = {}) =>
    request({
      url: '/reconciliation/apply',
      method: 'post',
      data,
    }),

  // 服务时长确认
  confirmReconciliation: (data = {}) =>
    request({
      url: '/reconciliation/confirm',
      method: 'post',
      data,
    }),

  // 申诉列表
  queryAppealList: (params = {}) =>
    request({
      url: '/reconciliation/appeal/page',
      method: 'get',
      params,
    }),

  // 导出对账详情
  exportReconciliationDetail: (params = {}) =>
    request({
      url: '/reconciliation/detail/export',
      method: 'get',
      params,
    }),

  queryTmsAddress: (
    params = {
      topAddressCode: 'CN',
    }
  ) =>
    request({
      url: '/applet/queryAddressTree',
      method: 'get',
      params,
    }),
}
