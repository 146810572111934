// import { request } from '../shared/request'
// import configInfo from '../../config/index'
import api from '@/api'
import Cookies from 'js-cookie'
import { store } from '../store'
import { MENU } from '../interface'
import Route from '../router'
import Altihex from '../component/altihex'
import { addWaterMarker } from '../shared/addWaterMark'

const { getSelectOptions, queryAddressReq, userInfoReq, getUserBusiness } = api

const VueModules = import.meta.glob('../../src/view/**/*.vue')
const TsxModules = import.meta.glob('../../src/view/**/*.tsx')
const modules = {
  ...VueModules,
  ...TsxModules,
}
import customRoute from '../../src/menu'
// import router from '../router'
interface UserInfo {
  userId: string
  userName: string
}

export const getGenerticData = (call: any): any => {
  const fn1 = getSelectOptions({
    bizType: 'EMPLOYEE',
  })
  const fn2 = queryAddressReq({
    parentCode: 100000,
  })
  Promise.all([fn1, fn2]).then((res) => {
    const [generticData, addressData] = res
    let addressRes = []
    if (addressData.length) {
      addressRes = addressData.map((item: Record<string, any>) => {
        return { value: item.code, label: item.name, isLeaf: false }
      })
    }
    store.commit('genertic/setGenerticDataSet', {
      ...generticData,
      provinceDataSource: addressRes,
    })
    if (call) call()
  })
}

let menuList: Array<MENU> = []
export const start = async (cb?: () => void): Promise<void> => {
  await login()
  await getMenuList()
  if (cb) {
    cb()
  }
}

export const getUserInfo = async (cb?: () => void): Promise<void> => {
  const userInfo: UserInfo = await userInfoReq()
  Cookies.set('userName', userInfo.userName)
  Cookies.set('userId', userInfo.userId)
  addWaterMarker(userInfo.userName)
  store.commit('_Altihex_/setUserInfo', userInfo)
  if (cb) cb()
}

/** 获取劳务公司列表 */
export const getBusinessList = async (): Promise<void> => {
  const businessList = await getUserBusiness()
  store.commit('_Altihex_/setBusinessList', businessList)
}

// 获取用户登陆信息
async function login() {
  let allowMenuList: any = []
  if (!['/', '/login'].includes(location.pathname)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    try {
      await getUserInfo()
      const { menuList } = store.state._Altihex_.userInfo
      allowMenuList = menuList || []
    } catch (err) {
      console.log(err)
    }
  }
  await initCustomRoute(allowMenuList)
}

async function initCustomRoute(allowMenuList): Promise<void> {
  function loop(customRoute: Array<MENU>) {
    customRoute.forEach((item: MENU) => {
      if (item.children && item.children.length > 0) {
        loop(item.children)
      } else {
        const flag = item.menuCode.indexOf('_altihex_') === 0
        const more = flag ? '/' : ''
        const component =
          modules[`../../src/view${item.menuUrl}/index.vue`] ||
          modules[`../../src/view${item.menuUrl}/index.tsx`]
        const moreComponent = flag ? Altihex : component
        Route.addRoute({
          path: `${more}${item.menuUrl}`,
          name: item.menuUrl,
          component: moreComponent,
        })
      }
    })
  }
  const nemCustomRoute: any = customRoute.filter((el) => {
    return allowMenuList.indexOf(el.menuCode) > -1
  })
  loop(nemCustomRoute)
  menuList = nemCustomRoute
  // loop(customRoute)
  // menuList = customRoute
}

// 获取菜单
async function getMenuList() {
  // const menuListResult = await request<Array<MENU> | undefined>({
  //   url: '/luna/menu/list',
  //   method: 'get',
  //   params: {
  //     backstageCode: configInfo.sysAclName,
  //   },
  // })
  // if (menuListResult && Array.isArray(menuListResult)) {
  //   menuList = [...menuList, ...menuListResult]
  // }
  await initRoute(menuList)
  store.commit('_Altihex_/setMenuList', menuList)
}

// 添加路由
async function initRoute(menuList: Array<MENU>): Promise<void> {
  function loop(menuList: Array<MENU>) {
    menuList.forEach((item) => {
      if (item.children && item.children.length > 0) {
        loop(item.children)
      } else {
        if (item.menuUrl || (item.children && item.children.length === 0)) {
          const flag = item.menuCode.indexOf('_altihex_') === 0
          const more = flag ? '/' : ''
          const component =
            modules[`../../src/view${item.menuUrl}/index.tsx`] ||
            modules[`../../src/view${item.menuUrl}/index.vue`]

          const moreComponent = flag ? Altihex : component
          Route.addRoute('empolee', {
            path: `/empolee${more}${item.menuUrl}`,
            name: item.menuUrl,
            component: moreComponent,
          })
        }
      }
    })
  }
  loop(menuList)
}
