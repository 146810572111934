import { defineComponent, h, PropType } from 'vue'
import { MENU } from '../../interface'
import * as Icons from '@ant-design/icons-vue'
export default defineComponent({
  name: 'MenuItem',
  props: {
    menuItem: {
      type: Object as PropType<MENU>,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
    },
  },
  setup(p) {
    const slots = (menuName: string, menuLogo: string | undefined) => {
      return {
        title() {
          return (
            <div>
              {menuLogo ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                h(Icons[p.menuItem.menuLogo])
              ) : (
                <likeOutlined></likeOutlined>
              )}
              <span>{menuName}</span>
            </div>
          )
        },
      }
    }
    return () => {
      if (p.menuItem.children && p.menuItem.children.length > 0) {
        return (
          <a-sub-menu
            v-slots={slots(p.menuItem.menuName, p.menuItem.menuLogo)}
            key={p.menuItem.menuUrl || p.menuItem.menuCode}>
            {p.menuItem.children.map((m) => {
              return (
                <menu-item
                  key={m.menuUrl || m.menuCode}
                  menuItem={m}></menu-item>
              )
            })}
          </a-sub-menu>
        )
      } else {
        return p.menuItem.enable ? (
          <a-menu-item key={p.menuItem.menuUrl}>
            <div>
              {p.menuItem.menuLogo ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                h(Icons[p.menuItem.menuLogo])
              ) : (
                <likeOutlined></likeOutlined>
              )}

              <span>{p.menuItem.menuName}</span>
            </div>
          </a-menu-item>
        ) : null
      }
    }
  },
})
