import moment from 'moment'
import idValidator from 'id-validator-modify'
import { cloneDeep } from 'lodash'
import { store } from '@core/store'
import API from '@/api'

/**
 * 数组增加全部
 * @param {String} str 字段名
 * @param {boolean} bool 布尔值
 * @return {Array}
 * */
export const generateCover = (str, bool = true) => {
  const dataSet = cloneDeep(store.state.genertic.generticDataSet[str])
  if (!dataSet || !dataSet.length) {
    return []
  } else {
    if (bool) {
      dataSet.unshift({
        value: '全部',
        code: 'all',
      })
      return dataSet
    } else {
      return dataSet
    }
  }
}

/**
 * 格式化数据请求结果
 * @param {Object} dataSet
 * @return {Object}
 * */
export const formatReqPaylod = (dataSet = {}, callback) => {
  let paylod = {}
  Object.keys(dataSet).forEach((item) => {
    if (
      (dataSet[item] || dataSet[item] === 0) &&
      !['All', 'all'].includes(dataSet[item])
    ) {
      paylod[item] = dataSet[item]
    }
  })
  if (callback) {
    return callback(paylod)
  }
  return paylod
}

/**
 * 从config中格式化分页取数
 * @param {Object} props
 * @return {Object}
 * */
export const formatPagination = ({ current = 1, pageSize }) => ({
  pageNum: current,
  pageSize,
})

export const formatPaginationNew = ({ current = 1, pageSize }) => ({
  page: current,
  page_size: pageSize,
})

export const certIdRegular = (id) => {
  // 加权因子
  const weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验码
  const check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

  const code = id + ''
  const last = id[17] //最后一位

  const seventeen = code.substring(0, 17)

  // 判断最后一位校验码是否正确
  const arr = seventeen.split('')
  const len = arr.length
  let num = 0
  for (let i = 0; i < len; i++) {
    num = num + arr[i] * weight_factor[i]
  }

  // 获取余数
  const resisue = num % 11
  const last_no = check_code[resisue]

  // 格式的正则
  // 正则思路
  /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
  const idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

  // 判断格式是否正确
  const format = idcard_patter.test(id)

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return last === last_no && format
}

export const cerIdValidatorRegular = (id) => {
  return new idValidator().isValid(id, 18)
}

export const getCerIdValidateInfo = (id) => {
  const { birth, ...restInfo } = new idValidator().getInfo(id, 18)
  const diffYears = moment().diff(moment(birth), 'years', true)

  return {
    ...restInfo,
    birth,
    age: Math.floor(diffYears),
  }
}

export const countdown = (count = 60, call) => {
  let timer = window.setInterval(() => {
    count--
    if (count <= 0) {
      clearInterval(timer)
      timer = null
    }
    if (call) call(count, count <= 0)
  }, 1000)
}

/**
 * 检查时间格式是否正确
 * */
export const checkMomentValid = (dateStr) => moment(dateStr).isValid()

/**
 * 返回金额的三种表现形式
 * @param {*} fen 金额，单位分
 * @param {*} type 表现类型 1：¥11.00 2：11.00元 3：11.00 4: 11
 * @returns {String} 显示金额
 */
export function displayMoney(fen, type = 1) {
  let negative = ''
  if (!fen) {
    return '-'
  }
  if (fen < 0) {
    negative = '-'
  }
  const r = /[0-9]+(\.[0-9]{1,2})?/
  const group = parseFloat(fen / 100)
    .toString()
    .match(r)
  if (!group[0]) return '0.00'

  const money = Number(group[0]).toFixed(2)
  const moneyWithoutFloatValue = Math.floor(money)

  if (type === 1) {
    return `${negative}¥${money}`
  } else if (type === 2) {
    return `${negative}${money}元`
  } else if (type === 3) {
    return `${negative}${money}`
  } else if (type === 4) {
    return `${negative}${moneyWithoutFloatValue}`
  }
}

/**
 * 校验银行卡信息
 * @param {Object} cardInfo 银行卡信息
 * @param {string} cardInfo.name 持卡人姓名
 * @param {string} cardInfo.cardNo 银行卡号
 * @param {string} cardInfo.certNo 持卡人身份证号
 * @param {boolean} isGetBankName 是否查询银行卡所属银行名称
 * @returns
 */
export const checkBankCardInfo = async (cardInfo, isGetBankName = true) => {
  const result = {}
  try {
    const checkResult = await API.checkBandCode(cardInfo)
    const { transStatus, channelResMsg } = checkResult || {}
    if (transStatus === 3) {
      result.success = true
      if (isGetBankName) {
        const bankInfo = await API.getBankName({ cardNo: cardInfo.cardNo })
        const bankName = bankInfo?.bankName
        if (bankName) {
          result.bankName = bankName
        } else {
          result.success = false
          result.msg = '银行卡号所属银行名称查询失败, 请重试!'
        }
      }
      return result
    } else {
      result.success = false
      result.msg = channelResMsg || '银行卡号校验失败, 请核对银行卡号'
      return result
    }
  } catch (e) {
    console.log(e)
    result.success = false
    result.msg = '银行卡号校验失败, 请核对银行卡号后重试'
    return result
  }
}
// 带时区的时间处理
export const handleTime = (time, format = 'YYYY-MM-DD') => {
  if (time) {
    return moment(time).format(format)
  } else {
    return '-'
  }
}
// 时间范围开始-00:00:00，结束23:59:59
export const handleRangeTime = (timeString, type) => {
  let res = ''
  if (type === 'start') {
    res = `${timeString} 00:00:00`
  } else if (type === 'end') {
    res = `${timeString} 23:59:59`
  }
  return moment(res).format()
}

export function updateTreeNodes(treeData) {
  treeData.forEach((node) => {
    if (node.level !== 3) {
      node.disabled = true
    }
    if (node.children) {
      updateTreeNodes(node.children)
    }
  })
  return treeData
}
// 输入框搜索项去掉前后空格
export const trimInput = (value) => {
  return value.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * dataUrl 转 blob
 * @param {*} dataurl
 * @returns
 */
export function dataURLToBlob(dataurl) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const img = new Image()
    img.src = dataurl
    img.crossOrigin = 'Anonymous'
    img.onload = function () {
      canvas.width = img.naturalWidth * window.devicePixelRatio
      canvas.height = img.naturalHeight * window.devicePixelRatio
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      canvas.toBlob((blob) => resolve(blob))
    }
    img.onerror = reject
  })
}

export function isAssets() {
  return ['pre', 'prod'].includes(process.env.BUILD_ENV)
}
