import { createStore } from 'vuex'
import storeModules from '@/store'
const store = createStore({
  modules: {
    ...storeModules,
    _Altihex_: {
      namespaced: true,
      state() {
        return {
          spinning: false,
          menuList: [],
          userInfo: [],
          businessList: [],
          currentMenuKey: '',
        }
      },
      mutations: {
        setMenuList(state, menuList) {
          state.menuList = menuList
        },
        setUserInfo(state, userInfo) {
          state.userInfo = userInfo
        },
        setBusinessList(state, businessList) {
          state.businessList = businessList
        },
        setCurrentMenuKey(state, key) {
          state.currentMenuKey = key
        },
        setSpinning(state, spinning) {
          state.spinning = spinning
        },
      },
    },
  },
})

export { store }
