import { App } from 'vue'
import { Router } from 'vue-router'
import { Store } from 'vuex'
import microApp from '@micro-zoe/micro-app'

console.log('main..')
microApp.start({
  lifeCycles: {
    created() {
      console.log('created..')
    },
  },
})

// if (process.env.NODE_ENV === 'development') {
//   import('@du/mock').then((mock) => {
//     mock.createProxy({
//       mockConfig: '',
//     })
//   })
// }

if (import.meta.env.DEV) {
  import('@du/mock').then((mock) => {
    mock.createProxy({
      mockConfig: import.meta.env.MOCK_CONFIG as any,
    })
  })
}

interface PARAMS {
  app: App
  router: Router
  store: Store<unknown>
}
export default ({ app, router, store }: PARAMS): void => {
  // console.log(app, store)
  // router.beforeEach(() => {
  //   console.log('进入页面前')
  // })
  // router.afterEach(() => {
  //   console.log('进入页面后')
  // })
}
