const state = {
  childOrderDetail: {},
  childOrderId: null,
  childOrderList: [],
}

const getters = {
  getChildOrderDetail: (state) => state.childOrderDetail,
  getChildOrderId: (state) => state.childOrderId,
  getChildOrderList: (state) => state.childOrderList,
}

const mutations = {
  setChildOrderDetail: (state, detail) => {
    state.childOrderDetail = detail
  },
  setChildOrderId: (state, id) => {
    state.childOrderId = id
  },
  setChildOrderList: (state, list) => {
    state.childOrderList = list
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
