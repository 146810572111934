import employee from './employee'
import demandResponse from './demandResponse'
import labour from './labour'
import genertic from './genertic'
import demandResponseDetail from './demandResponseDetail'

export default {
  employee,
  demandResponse,
  genertic,
  labour,
  demandResponseDetail,
}
