import { defineComponent, ref, computed, watch } from 'vue'
import MenuItem from './item'
import './index.less'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { MENU } from '../../interface'

export default defineComponent({
  name: 'Menu',
  components: {
    MenuItem,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup(p) {
    const store = useStore()
    const router = useRouter()
    const menuList = computed<Array<MENU>>(() => store.state._Altihex_.menuList)
    console.log('menuList', menuList.value)
    const openKeys = ref<string[]>([])
    const selectedKeys = ref<string[]>([])
    const clickMenuItem = (params: any) => {
      const more = params.key[0] === '/' ? '' : '/'
      if (params.key.includes('lms-admin-child')) {
        router.push(`${params.key}`)
        return
      }
      router.push(`/empolee${more}${params.key}`)
    }
    console.log(
      'store.state._Altihex_.currentMenuKey',
      store.state._Altihex_.currentMenuKey
    )
    // 监听刚进入时路由的信，对menu进行更改
    watch(
      () => store.state._Altihex_.currentMenuKey,
      (newValue) => {
        const menuList: Array<MENU> = store.state._Altihex_.menuList
        let openKeysA: Array<string> = []
        let selectedKeysA: Array<string> = []
        let returnFlag = false
        const loop = (menuList: Array<MENU>, flag: boolean) => {
          for (let i = 0; i < menuList.length; i++) {
            if (returnFlag) {
              return
            }
            if (flag) {
              openKeysA = []
              selectedKeysA = []
            }
            const { children, menuCode, menuUrl } = menuList[i]
            if (children && children.length > 0) {
              openKeysA.push(menuCode)
              loop(children, false)
            } else {
              const isAltihex = menuCode.indexOf('_altihex_') === 0 ? '/' : ''
              if (`${isAltihex}${menuUrl}` === newValue) {
                selectedKeysA.push(`${isAltihex}${menuUrl}`.substr(1))
                returnFlag = true
                return
              }
            }
          }
        }
        loop(menuList, true)
        openKeys.value = selectedKeysA.length === 0 ? [] : openKeysA
        selectedKeys.value = selectedKeysA
      }
    )
    return () => {
      selectedKeys.value = [
        store.state._Altihex_.currentMenuKey.includes('lms-admin-child')
          ? store.state._Altihex_.currentMenuKey
          : store.state._Altihex_.currentMenuKey.split('/empolee')[1] || '/',
      ]
      return (
        <a-menu
          class='altihex-menu'
          mode='inline'
          theme='dark'
          onClick={clickMenuItem}
          inline-collapsed={p.collapsed}
          v-models={[
            [selectedKeys.value, 'selectedKeys'],
            [openKeys.value, 'openKeys'],
          ]}>
          {menuList.value.map((m) => {
            return (
              <menu-item key={m.menuUrl || m.menuCode} menuItem={m}></menu-item>
            )
          })}
        </a-menu>
      )
    }
  },
})
