import { defineComponent, createVNode, computed } from 'vue'
import { message } from 'altihex-design'
import { Modal } from 'altihex-design'
import { useStore } from 'vuex'
import Cookie from 'js-cookie'
import { removeEmpoleeToken } from '../../shared/auth'
import api from '@/api'
import { QuestionCircleOutlined } from '@ant-design/icons-vue'
import './index.less'
import router from '../../router'
import { getBusinessList } from '../../bootstrap'

const { logoutReq, businessSwitch } = api

export default defineComponent({
  name: 'LayoutUser',
  setup() {
    const store = useStore()
    const businessList = computed(() => store.state._Altihex_.businessList)
    const userInfo = computed(() => store.state._Altihex_.userInfo)
    let loading = false
    getBusinessList()
    const doLogout = () => {
      Modal.confirm({
        okText: '确定',
        cancelText: '取消',
        title: '您确定要退出登录吗？',
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => {
          // removeToken()
          // goToSso()
          logoutReq().finally(() => {
            removeEmpoleeToken(Cookie)
            router.push('/login')
          })
        },
      })
    }
    const changeUser = ({ businessCode }) => {
      if (businessCode === userInfo.value.businessCode) {
        return
      }
      if (loading) {
        return
      }
      loading = message.loading('切换用户中', 0)
      businessSwitch({ businessCode })
        .then(() => {
          window.location.href = '/'
        })
        .finally(() => {
          loading()
          loading = false
        })
    }
    const dropSlots = {
      overlay() {
        return (
          <a-menu>
            <a-menu-item>
              <a href='javascript:;' onClick={doLogout}>
                退出登录
              </a>
            </a-menu-item>
          </a-menu>
        )
      },
    }
    const businessSlots = {
      overlay() {
        return (
          <a-menu>
            {(businessList.value || []).map((item) => {
              return (
                <a-menu-item
                  onClick={() => {
                    changeUser(item)
                  }}>
                  {item.businessShortName}
                </a-menu-item>
              )
            })}
          </a-menu>
        )
      },
    }
    return () => (
      <div>
        <a-dropdown v-slots={businessSlots}>
          <a class='buseinss-select'>{userInfo.value.businessName}</a>
        </a-dropdown>
        <a-dropdown v-slots={dropSlots}>
          <a-avatar class='altihex-avatar'>{userInfo.value.userName}</a-avatar>;
        </a-dropdown>
      </div>
    )
  },
})
