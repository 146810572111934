import { request } from '@request'
const prefix = '/zhaopin-outside/labour_company'

/**
 * 劳务公司-用工需求详情下面的反馈列表
 * @param data
 * @returns
 */
export const fetchFeedbackList = (data = {}) =>
  request({
    url: `${prefix}/labor_demand/feedback_list`,
    method: 'post',
    data,
  })
/**
 * 劳务公司-获取Ai面试列表
 * @param data
 * @returns
 */
export const fetchNoticeAiList = (data = {}) =>
  request({
    url: `${prefix}/labor_demand/ai_notice_list`,
    method: 'post',
    data,
  })
/**
 * 劳务公司-获取Ai面试面试反馈列表
 * @param data
 * @returns
 */
export const fetchMIANSHIFANKUIAiList = (data = {}) =>
  request({
    url: `${prefix}/labor_demand/ai_feedback_list`,
    method: 'post',
    data,
  })
/**
 * 劳务公司-获取Ai面试线下参观列表
 * @param data
 * @returns
 */
export const fetchXIANXIACANGUANAiList = (data = {}) =>
  request({
    url: `${prefix}/labor_demand/ai_visit_list`,
    method: 'post',
    data,
  })
