import { defineComponent } from 'vue'
import ConfigInfo from '../../../config/index'
import './index.less'

export default defineComponent({
  name: 'LayoutLogo',
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup(p) {
    return () => (
      <div class='director-logo'>
        {/*<img src='//du.hupucdn.com/FjcCn2rtFf-h6r2pt--N4DGfkY7a' alt='' />*/}
        <h3 v-show={!p.collapsed} class='director-logo__title'>
          {ConfigInfo.projectName}
        </h3>
      </div>
    )
  },
})
