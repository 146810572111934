import { default as employeeModal } from './employeeModal'
import { default as laborDispatch } from './laborDispatch'
import { default as commonModal } from './common'
import { default as abnormalPay } from './abnormalPay'
import { default as endServiceApply } from './endServiceApply'

export default {
  ...employeeModal,
  ...laborDispatch,
  ...commonModal,
  ...endServiceApply,
  abnormalPay,
}
