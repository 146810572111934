import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios'
import Cookie from 'js-cookie'
import { parseTokenDraft } from './parseTokenDraft'
import { getEmpoleeToken, goToEmpoleeLogin, removeEmpoleeToken } from './auth'
import configInfo from '../../config/index'
import { message } from 'altihex-design'
import { store } from '../store'
import { getGrap } from './grapPolris'
interface REQUESTPARAMS extends AxiosRequestConfig {
  url: string
  method?: Method
  loading?: boolean
  successMsg?: string
  data?: Record<string, any>
  params?: Record<string, any>
}

const inXDW = () => {
  try {
    const href = document.location.href
    // 只有国内生产代码才执行
    if (!href.includes('https://duyu-labour.com')) {
      return false
    }
    const request = new XMLHttpRequest()
    request.open('GET', document.location.href, false)
    request.send(null)
    const header = request.getResponseHeader('rr')
    console.log('inXDW-header', href, header)
    return header === 'xiaodewu'
  } catch (e) {
    console.log('inXDW-error', e)
  }
}

const dataArea = ['post', 'POST', 'delete', 'DELETE', 'PUT', 'put']
const paramArea = ['get', 'GET']
const instance = axios.create({
  baseURL: configInfo.api.baseURL(),
  timeout: 5000,
  method: 'get',
  params: {},
  data: {},
  headers: {
    backstageCode: configInfo.sysAclName,
    userId: 1,
    originURL: window.location.href,
    isEfe: 1,
    ...getGrap(),
    ...(inXDW() && {
      'x-deploy-flowtype': 'CE_BLUE',
    }),
  },
})
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const dutoken = getEmpoleeToken(Cookie)
  const { data, params, method, headers } = config
  if (dutoken) {
    headers.duToken = dutoken
  }
  if (method && dataArea.indexOf(method) >= 0) {
    data.sign = parseTokenDraft(data)
  }
  if (method && paramArea.indexOf(method) >= 0) {
    params.sign = parseTokenDraft(params)
  }
  // change this baseURL of this specific request
  // use case is: https://t1.duyu-labour.com/api/v1/h5/admin/excel/uploadLog/pageList
  if (
    config.url?.includes('admin/excel/uploadLog/pageList') ||
    config.url?.includes('oss-platform/token/formToken') ||
    config.url?.includes('payacq/bankcard/') ||
    config.url?.includes('supplier/oss') ||
    config.url?.includes('ehr-lms-internal/open-api') ||
    config.url?.includes('scc-route/reportCenter') ||
    config.url?.includes('ehr-lms-outside/open-api') ||
    config.url?.includes('/zhaopin-outside/')
  ) {
    config.baseURL = config.baseURL?.replace('/workforce/supplier/api', '')
  }
  if (config.url?.includes('finance-payroll/labor')) {
    config.baseURL = config.baseURL?.replace('/workforce/supplier/api', '')
  }
  // 全站迁移到 workforce 但 /finance-payroll 不迁移
  if (config.url?.startsWith('/finance-payroll/')) {
    config.baseURL = config.baseURL?.replace('/workforce', '')
  }
  return config
})
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

function request<T>(configure: REQUESTPARAMS): Promise<T> {
  const {
    url,
    method,
    data,
    params,
    loading,
    successMsg,
    timeout = 5000,
  } = configure
  if (loading) {
    store.commit('_Altihex_/setSpinning', true)
  }
  return new Promise((resolve, reject) => {
    instance({
      method,
      url,
      ...(data ? { data } : {}),
      ...(params ? { params } : {}),
      timeout,
    }).then(
      (res: AxiosResponse) => {
        const {
          status,
          data: { code, data, msg },
        } = res
        if (status === 200) {
          if (code === 200) {
            resolve(data)
            if (successMsg) {
              message.success(successMsg)
            }
          } else if (code === 305) {
            message.error(msg)
            reject(data)
            removeEmpoleeToken(Cookie)
            goToEmpoleeLogin()
          } else {
            message.error(msg)
            reject(data)
          }
        }
        store.commit('_Altihex_/setSpinning', false)
      },
      (error) => {
        const {
          status,
          data: { code, msg },
        } = error.response || { data: {} }
        // console.log(122112, error.response, status, code);
        if (status === 401) {
          // 305会话过期
          if ([303, 305, 307].includes(code)) {
            removeEmpoleeToken(Cookie)
            goToEmpoleeLogin()
          }
          message.error(msg)
        }
        reject(error)
        store.commit('_Altihex_/setSpinning', false)
      }
    )
  })
}

export { request }
