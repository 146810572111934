import token from '@du/multi-environment-login'
import configInfo from '../../config/index'
import router from '../router'

const developmentEnv = configInfo.developmentEnv
const envUrl = `http://${developmentEnv}-authgw.dewu.com`
// 被识别的域名前缀
export const domainPrefixList = [
  'd1',
  'd2',
  't0',
  't1',
  't2',
  't3',
  't4',
  't5',
  't99',
  'ft',
  'cstst',
  'pre',
]

//清除所有cookie函数
const clearAllCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?==)/g)
  if (keys) {
    for (let i = keys.length; i--; )
      document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
  }
}

// 获取格式如 d1-eos 的环境
export function getUrlEnv(url = ''): string {
  const reg = /https?:\/\/(.*)\.(shizhuang-inc|dewu)/
  const regMatch = url.match(reg)
  return url && regMatch ? regMatch[1] : ''
}

export function getEnv(): string {
  const host =
    process.env.NODE_ENV === 'development'
      ? getUrlEnv(envUrl)
      : window.location.hostname.split('.shizhuang-inc')[0]
  const env = host ? host.split('-')[0] : ''
  if (domainPrefixList.includes(env)) {
    return env
  } else {
    // 默认一定要用prod不要用空字符串
    return 'prod'
  }
}
export function getToken(): string {
  return token.getToken(getEnv())
}

export function removeToken(): string {
  return token.removeToken(getEnv())
}

export function getEmpoleeToken(Cookie: any): string {
  return <string>Cookie.get('duToken')
}

export function removeEmpoleeToken(Cookie: any): void {
  localStorage.clear()
  clearAllCookie()
  return Cookie.remove('duToken')
}

export const goToSso = (): void => {
  const isDev = process.env.NODE_ENV === 'development'
  const hostname = isDev ? envUrl : window.location.origin
  const matchString = hostname.match(/\/\/([^-.+]+)/)
  const devSsoPrefix = matchString && matchString[1]
  let ssoUrl = ''
  if (devSsoPrefix && domainPrefixList.includes(devSsoPrefix)) {
    // 开发和测试环境的SSO
    ssoUrl = `http://${devSsoPrefix}-sso.shizhuang-inc.com`
  } else {
    // 生产环境的SSO
    ssoUrl = 'https://sso.shizhuang-inc.com'
  }
  const url = `${ssoUrl}?returnUrl=${encodeURIComponent(location.href)}`

  window.location.href = url
}

export const goToEmpoleeLogin = (): void => {
  router.push('/login')
}
