import { createApp } from 'vue'
import App from '../src/App'
import Antd from 'altihex-design'
import customMain from '../src/main'
import { start } from './bootstrap/index'
import router from './router'
import { store } from './store'
import 'altihex-design/dist/altihex.css'
import * as Icons from '@ant-design/icons-vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import promiseFinally from 'promise.prototype.finally'
import SliderVerify from 'slider-verify-v3'
import 'slider-verify-v3/lib/SliderVerify.css'

// 对页面进行权限验证
start(() => {
  promiseFinally.shim()
  const app = createApp(App)
  for (const i in Icons) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    app.component(i, Icons[i])
  }
  app.use(router)
  app.use(Antd)
  app.use(store)
  app.use(SliderVerify)
  customMain({ app, router, store })
  app.mount('#app')
})
