export default [
  // {
  //   enable: true,
  //   menuUrl: 'mendao_order-list',
  //   menuName: '自定义',
  //   menuCode: '_altihex_mendao_order-list',
  // },
  {
    enable: true,
    menuName: '劳动力管理',
    menuCode: 'workFroceManagement',
    menuUrl: '/workFroceManagement',
    menuLogo: '',
  },
  // {
  //   enable: true,
  //   menuName: '合作公司信息管理',
  //   menuCode: 'partnerManagement',
  //   menuUrl: '/partnerManagement',
  // },
  {
    enable: true,
    menuName: '用工需求响应',
    menuCode: 'empolyDemandResponse',
    menuUrl: '/empolyDemandResponse',
  },
  {
    enable: false,
    menuName: '用工需求详情',
    menuCode: 'empolyDemandResponseDetail',
    menuUrl: '/empolyDemandResponseDetail',
  },
  {
    enable: true,
    menuName: '通知面试名单',
    menuCode: 'interviewList',
    menuUrl: '/interviewList',
  },
  {
    enable: true,
    menuName: '排班表',
    menuCode: 'schedule',
    menuUrl: '/schedule',
  },
  {
    enable: true,
    menuName: '每日考勤查询',
    menuCode: 'dailyAttendance',
    menuUrl: '/dailyAttendance',
  },
  {
    enable: true,
    menuName: '服务时长对账',
    menuCode: 'reconciliation',
    menuUrl: '/reconciliation',
  },
  {
    enable: false,
    menuName: '服务时长对账详情',
    menuCode: 'reconciliationDetail',
    menuUrl: '/reconciliationDetail',
  },
  {
    enable: true,
    menuName: '服务结束申请',
    menuCode: 'endServiceApply',
    menuUrl: '/endServiceApply',
  },
  {
    enable: true,
    menuName: '申诉记录',
    menuCode: 'appealRecord',
    menuUrl: '/appealRecord',
  },
  {
    enable: true,
    menuName: '异常赔付',
    menuCode: 'abnormal-pay',
    menuUrl: '/abnormal-pay',
  },
  {
    enable: true,
    menuName: '供应商维护',
    menuCode: 'supplierMaintain',
    menuUrl: '/supplierMaintain',
  },
  {
    enable: true,
    menuName: '移动端报名要求录入',
    menuCode: 'useRequired',
    menuUrl: '/useRequired',
  },
  {
    enable: true,
    menuName: '劳动力审核&提交',
    menuCode: 'workFroceApprove',
    menuUrl: '/workFroceApprove',
  },
  // {
  //   enable: true,
  //   menuName: '工资单上传',
  //   menuCode: 'payrollUpload',
  //   menuUrl: '/payrollUpload',
  // },
  {
    enable: false,
    menuName: '工资单上传详情',
    menuCode: 'payrollUploadDetail',
    menuUrl: '/payrollUploadDetail',
  },

  // {
  //   enable: true,
  //   menuName: '薪资申诉管理',
  //   menuCode: 'payrollAppeal',
  //   menuUrl: '/payrollAppeal',
  // },
  {
    enable: true,
    menuName: '账号权限分发',
    menuCode: 'accountPermission',
    menuUrl: '/accountPermission',
  },
  {
    enable: true,
    menuName: '劳工薪资数据管理',
    menuCode: 'workerSalaryManagement',
    menuUrl: '/workerSalaryManagement',
  },
  {
    enable: true,
    menuName: '劳工薪资数据审核',
    menuCode: 'workerSalaryAudit',
    menuUrl: '/workerSalaryAudit',
  },
  {
    enable: true,
    menuName: '招聘推广内容编辑',
    menuCode: 'employPromote',
    menuUrl: '/employPromote',
  },
  {
    enable: true,
    menuName: '推广结果查询',
    menuCode: 'promoteResultQuery',
    menuUrl: '/promoteResultQuery',
  },
  {
    enable: true,
    menuName: '推荐人列表',
    menuCode: 'promoteUserList',
    menuUrl: '/promoteUserList',
  },
  {
    enable: true,
    menuName: '负激励查看(新)',
    menuCode: 'negativeIncentive',
    menuUrl: '/negativeIncentive',
  },
  {
    enable: true,
    menuName: '负激励查看',
    menuCode: 'negativeIncentiveOld',
    menuUrl: '/negativeIncentiveOld',
  },
  {
    enable: true,
    menuName: '服务变更查看',
    menuCode: 'serviceChange',
    menuUrl: '/serviceChange',
  },
  {
    enable: true,
    menuName: '考勤异常人员明细表',
    menuCode: 'inventory-of-abnormal',
    menuUrl: '/inventory-of-abnormal',
  },
  {
    enable: true,
    menuName: '待开始服务人员管理',
    menuCode: 'servicePersonal',
    menuUrl: '/servicePersonal',
  },
  {
    enable: false,
    menuName: '进场员工详情',
    menuCode: 'servicePersonalDetail',
    menuUrl: '/servicePersonalDetail',
  },
  {
    enable: true,
    menuName: '一线伙伴服务结束管理',
    menuCode: 'ehrLmsDimission',
    menuUrl: '/ehrLmsDimission',
  },
  {
    enable: true,
    menuName: '测试子应用',
    menuCode: 'lms-admin-child',
    menuUrl: '/lms-admin-child/test1',
  },
  {
    enable: true,
    menuName: '劳动力管理【灰】',
    menuCode: 'workFroceManagementNew',
    menuUrl: '/workFroceManagementNew',
    menuLogo: '',
  },
  {
    enable: true,
    menuName: '用工需求响应【灰】',
    menuCode: 'empolyDemandResponseNew',
    menuUrl: '/empolyDemandResponseNew',
  },
  {
    enable: true,
    menuName: '招聘官管理【灰】',
    menuCode: 'recruitor-list',
    menuUrl: '/recruitor-list',
  },
  {
    enable: true,
    menuName: '招聘渠道管理【灰】',
    menuCode: 'recruitor-channel',
    menuUrl: '/recruitor-channel',
  },
]
