import { defineComponent, ref } from 'vue'
import './index.less'
import LayoutHeader from '../layoutHeader'
import LayoutMenu from '../layoutMenu'
import LayoutLogo from '../layoutLogo'
import { Layout } from 'altihex-design'
import { start } from '../../bootstrap/index'
import { useRoute } from 'vue-router'
// import Route from '../../router'
export default defineComponent({
  name: 'LayoutMain',
  components: {
    LayoutHeader,
    LayoutLogo,
    LayoutMenu,
  },
  setup() {
    // 侧边栏开关
    const collapsed = ref<boolean>(false)
    // Route.removeRoute('empolee')
    // console.log(Route.getRoutes())
    // 进main页面开始开关
    const route = useRoute()
    console.log('layout.tsx route', route, route.path, route.name)

    start()
    return () => (
      <Layout class='director-layout'>
        <a-layout-sider v-model={[collapsed.value, 'collapsed']}>
          <layout-logo collapsed={collapsed.value} />
          <layout-menu collapsed={collapsed.value} />
        </a-layout-sider>
        <a-layout style='background: #ffffff'>
          <layout-header v-model={[collapsed.value, 'collapsed']} />
          <a-layout-content class='director-layout__content'>
            <router-view key={route.name} />
          </a-layout-content>
        </a-layout>
      </Layout>
    )
  },
})
