import { createRouter, createWebHistory } from 'vue-router'
import { store } from './store'
import Cookie from 'js-cookie'
import { getEmpoleeToken } from './shared/auth'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import { getGenerticData } from './bootstrap'
NProgress.configure({ showSpinner: false })

// const routes: Array<RouteRecordRaw> = [];
const router = createRouter({
  history: createWebHistory(),
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@view/index.vue'),
    // },
    {
      path: '/',
      redirect: '/empolee',
    },
    {
      path: '/empolee',
      name: 'empolee',
      component: () => import('../core/component/layoutMain'),
      children: [
        {
          path: '/lms-admin-child/:page*',
          name: 'lms-admin-child',
          component: () =>
            import(
              /* webpackChunkName: "lms-admin-child" */ '@/view/child/index.vue'
            ),
        },
        {
          path: '/',
          component: () => import('@view/index.vue'),
        },
        {
          path: '/empolee',
          component: () => import('@view/index.vue'),
        },
        {
          path: '/importInBatchesResult',
          name: '/importInBatchesResult',
          component: () => import('@view/importInBatchesResult/index.vue'),
        },
        {
          path: '/importInBatchesResultNew',
          name: '/importInBatchesResultNew',
          component: () => import('@view/importInBatchesResultNew/index.vue'),
        },
        {
          path: '/workFroceApprove/importInBatchesResult',
          name: 'workFroceApproveResult',
          component: () =>
            import('@view/workFroceApprove/importInBatchesResult/index.vue'),
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@view/login/index.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import(`./component/notFound/index`),
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { fullPath: toPath } = to
  // 存入store
  if (getEmpoleeToken(Cookie)) {
    store.commit('_Altihex_/setCurrentMenuKey', toPath)
    NProgress.start()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // const info = store.state.genertic.generticDataSet
    // if (toPath !== '/login' && (!info || !Object.keys(info).length)) {
    //   getGenerticData(() => {
    //     next()
    //   })
    // } else {
    //   next()
    // }
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      //不然就跳转到登录；
      next('/login')
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
