import { request } from '@request'

export default {
  // 信息枚举
  getSelectOptions: (params = {}) =>
    request({
      url: '/select/options',
      method: 'get',
      params,
    }),

  // 登录
  loginReq: (params = {}) =>
    request({
      url: '/login',
      method: 'post',
      data: params,
    }),

  // 获取验证码
  getCaptchaReq: (params = {}) =>
    request({
      url: '/getCaptcha',
      method: 'post',
      data: params,
    }),

  // 获取用户信息
  userInfoReq: (params = {}) =>
    request({
      url: '/login/user',
      method: 'get',
      params,
    }),
  /** 获取用户供应商用户 */
  getUserBusiness: (params = {}) =>
    request({
      url: '/getUserBusiness',
      method: 'get',
      params,
    }),
  /** 切换用户供应商用户 */
  businessSwitch: (data = {}) =>
    request({
      url: '/switch',
      method: 'post',
      data,
    }),
  // 登出
  logoutReq: (params = {}) =>
    request({
      url: '/logout',
      method: 'get',
      data: params,
    }),

  // 列表导出
  reportExportReq: (params = {}) =>
    request({
      url: '/download/result',
      method: 'get',
      params,
    }),
  checkBandCode: (data) => {
    data.reqNo = 'dx' + new Date().getTime() + data.cardNo.substr(6, 4)
    data.cardType = '1'
    data.certType = '0'
    data.productCode = 'PAY_WAGE_BUSINESS'
    return request({
      url: '/payacq/bankcard/qso/sendMsgAuthenticate',
      method: 'POST',
      data,
    })
  },
  getBankName: (data) => {
    data.reqNo = 'dxn' + new Date().getTime() + data.cardNo.substr(6, 4)
    return request({
      url: '/payacq/bankcard/getCardbin',
      method: 'POST',
      data,
    })
  },
  getServiceType: (data = {}) =>
    request({
      url: '/employee/getServiceType',
      method: 'POST',
      data,
    }),
    getMaskField: (data) => {
      return request({
        url: '/employee/getMaskField',
        method: 'POST',
        data,
      })
    }
}