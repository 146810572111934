const state = {
  employeeDetail: {},
  employeeEnums: {},
  employeeAdress: [],
  employeeLowAdress: [],
}

const getters = {
  getEmployeeDetail: (state) => state.employeeDetail,
  getEmployeeEnums: (state) => state.employeeEnums,
  getEmployeeAdress: (state) => state.employeeAdress,
  getEmployeeLowAdress: (state) => state.employeeLowAdress,
}

const mutations = {
  setEmployeeDetail: (state, detail) => {
    state.employeeDetail = detail
  },
  setEmployeeEnums: (state, data) => {
    state.employeeEnums = data
  },
  setEmployeeAddress: (state, data) => {
    state.employeeAdress = data
  },
  setEmployeeLowAddress: (state, data) => {
    state.employeeLowAdress = data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
