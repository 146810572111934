import { request } from '@request'

export default {
  // 服务结束审批查询
  queryServiceEndList: (data = {}) =>
    request({
      url: '/serviceEnd/list',
      method: 'post',
      data,
    }),

  // 人员查询
  getUserList: (data = {}) =>
    request({
      url: '/serviceEnd/userList',
      method: 'post',
      data,
    }),

  // 导出
  exportServiceEndList: (params = {}) =>
    request({
      url: '/serviceEnd/export',
      method: 'post',
      data: params,
    }),

  // 服务结束申请提交
  serviceEndSubmit: (params = {}) =>
    request({
      url: '/serviceEnd/submit',
      method: 'post',
      data: params,
    }),

  // 审核进度
  getServiceEndDetail: (params = {}) =>
    request({
      url: '/serviceEnd/detail',
      method: 'get',
      params,
    }),

  // 审批驳回
  serviceEndReject: (data) =>
    request({
      url: '/serviceEnd/unPass',
      method: 'post',
      data,
    }),

  // 审批通过
  serviceEndPass: (data) =>
    request({
      url: '/serviceEnd/pass',
      method: 'post',
      data,
    }),

  // 当前人员信息
  getUserInfo: (params = {}) =>
    request({
      url: '/login/user/info',
      method: 'get',
      params,
    }),

  // 服务结束原因（枚举）
  reasonTypes: (params = {}) =>
    request({
      url: 'serviceEnd/reasonTypes/v2',
      method: 'get',
      params,
    }),
}
