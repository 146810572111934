const state = {
  reconciliationDetail: {},
}

const getters = {
  getReconciliationDetail: (state) => state.reconciliationDetail,
}

const mutations = {
  setReconciliationDetail: (state, detail) => {
    state.reconciliationDetail = detail
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
