import Md5 from 'md5'

interface IObject {
  [key: string]: any
}
/**
 * 验签通用算法（实验阶段）
 * @param params 验签对象
 * @param salt 盐
 * @param isDebug 是否为debug模式
 */
export function parseTokenDraft(
  params: IObject,
  salt = '048a9c4943398714b356a696503d2d36',
  isDebug = false
): string {
  if (isDebug) {
    console.log('转化前params=', params)
  }
  const replacer = (_: any, v: any) => (v === null ? undefined : v)

  const getValueString = (val: any): any => {
    if ([undefined, null, ''].includes(val)) return ''
    if (Object.prototype.toString.call(val) === '[object Object]')
      return JSON.stringify(val, replacer)
    if (Array.isArray(val)) {
      let tempStr = ''
      val.forEach((v, i) => {
        if (Object.prototype.toString.call(v) === '[object Object]') {
          tempStr += JSON.stringify(v, replacer)
        } else if ([undefined, null].includes(v)) {
          tempStr += null
        } else {
          tempStr += v.toString()
        }
        if (i < val.length - 1) {
          // 不是最后一个
          tempStr += ','
        }
      })
      return tempStr
    }
    return val.toString()
  }

  let paramsToken = Object.keys(params)
    .sort()
    .reduce((acc: any, key: any) => {
      if (params[key] === undefined) {
        // 第一层过滤掉undefined，因为undefined不上传，key值也不应该存在
        return acc
      }
      return acc + key + getValueString(params[key])
    }, '')

  if (isDebug) {
    console.log('转化后paramsToken=', paramsToken)
    console.log('salt=', salt)
  }

  if (/[\u00A0\u3000]/g.test(paramsToken)) {
    console.warn('验签警告：请先去除非法字符\\u00A0，\\u3000')
  }

  paramsToken += salt

  return Md5(paramsToken) as string
}
