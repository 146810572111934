import { request } from '@request'

export default {
  // 根据条件查询用工人员列表
  queryEmployeeList: (data = {}) =>
    request({
      url: '/employee/list',
      method: 'post',
      data,
    }),

  // 导出用工人员信息
  exportEmployeeList: (params = {}) =>
    request({
      url: '/employee/exportEmployee',
      method: 'post',
      data: params,
    }),

  // 更新雇员服务状态
  employeeUpdate: (params = {}) =>
    request({
      url: '/employee/updateStatus',
      method: 'post',
      data: params,
    }),

  // 新增/编辑用工人员信息
  employeeEditReq: (params = {}) =>
    request({
      url: '/employee/edit',
      method: 'post',
      data: params,
    }),

  // 劳动力面试人员列表
  listInterviewerReq: (params = {}) =>
    request({
      url: '/employee/listInterviewer',
      method: 'post',
      data: params,
    }),

  // 导出通知面试人员列表
  exportInterviewerReq: (params = {}) =>
    request({
      url: '/employee/exportInterviewer',
      method: 'post',
      data: params,
    }),

  // 用工需求响应列表
  listOrderResponseReq: (params = {}) =>
    request({
      url: '/employee/listOrderResponse',
      method: 'post',
      data: params,
    }),

  // 根据子任务筛选人员
  listEmployeeByChildDetailIdReq: (params = {}) =>
    request({
      url: '/employee/listEmployeeByChildOrderId',
      method: 'post',
      data: params,
    }),

  // 子任务详情
  childOrderDetailReq: (params = {}) =>
    request({
      url: '/employee/childOrderDetail',
      method: 'post',
      data: params,
    }),

  // 子任务绑定人员
  bindChildAndEmployeeReq: (params = {}) =>
    request({
      url: '/employee/bindChildAndEmployee',
      method: 'post',
      data: params,
    }),

  // 用工需求列表导出
  laborOrderExportReq: (params = {}) =>
    request({
      url: '/employee/exportOrderResponse',
      method: 'post',
      data: params,
    }),

  // 查询省市信息
  queryAddress: (params = {}) =>
    request({
      url: '/address/query',
      method: 'get',
      params,
    }),

  // 获取绑定人员信息--劳务公司后台
  listBindEmployeeReq: (params = {}) =>
    request({
      url: '/employee/listBindEmployee',
      method: 'post',
      data: params,
    }),

  // 查询用工详情
  queryEmployeeDetail: (params = {}) =>
    request({
      url: '/employee/detail',
      method: 'get',
      params,
    }),

  // 操作日志查询
  logFindReq: (params = {}) =>
    request({
      url: 'log/find',
      method: 'get',
      params,
    }),

  // import in batches
  importInBatchesReq: (params) =>
    request({
      url: 'employee/excelImport/add',
      method: 'post',
      data: params,
    }),

  // get import results
  getImportResultsReq: (params) =>
    request({
      url: 'admin/excel/uploadLog/pageList',
      method: 'get',
      params,
    }),

  // 驳回
  employeeReject: (data) =>
    request({
      url: '/employee/reject',
      method: 'post',
      data,
    }),

  employeeserviceEnd: (data) =>
    request({
      url: '/employee/serviceEnd',
      method: 'post',
      data,
    }),

  checkFreezeDay: (data) =>
    request({
      url: '/employee/checkFreezeDay',
      method: 'post',
      data,
    }),
}
