const state = {
  generticDataSet: {},
}

const getters = {
  getGenerticData: (state) => state.generticDataSet,
}

const mutations = {
  setGenerticDataSet: (state, dataSet) => {
    state.generticDataSet = {
      ...state.generticDataSet,
      ...dataSet,
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
