import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import { BellOutlined } from '@ant-design/icons-vue'
import moment from 'moment'
import { DateTimeFormat } from './helper'
import { getMsgList, getIsLatestMsg } from './api'
import './index.less'

let timer: number | undefined

export default defineComponent({
  name: 'LayoutNews',
  setup(p) {
    const msgList = ref([])
    const isLaster = ref(true) // 是否是最新消息
    const loading = ref(false)

    // 开启定时器，八秒调用一次
    const openTimeout = () => {
      timer = setInterval(() => {
        loadLatestMsg()
      }, 8000)
    }

    // 进入页面立即调用一次，之后八秒调用一次
    onMounted(() => {
      loadLatestMsg()
      openTimeout()
    })

    // 组件销毁时清除定时器
    onUnmounted(() => {
      clearInterval(timer)
    })

    // 关闭弹窗时，开启定时器
    const onVisibleChange = (visible: boolean) => {
      if (visible) return
      openTimeout()
    }

    // 拿到是否是最新消息
    const loadLatestMsg = async () => {
      const data = await getIsLatestMsg()
      const { latest } = data || {}
      isLaster.value = latest
    }

    // 获取消息列表
    const handleMsgList = async (e) => {
      e?.stopPropagation()
      loading.value = true
      isLaster.value = true
      clearInterval(timer) // 打开弹窗后，清除定时器
      const data = await getMsgList()
      setTimeout(() => {
        loading.value = false
        msgList.value = data || []
      }, 300)
    }

    // 消息内容
    const messageContent = (
      <div class='message-wrap'>
        <a-spin spinning={loading.value}>
          {msgList?.value?.length > 0 ? <>
            {msgList?.value?.map((msg, index) => {
              return <div key={msg.id}>
                <div class='title'>{msg.title}</div>
                <div class='date'>{moment(msg.create_time).format(DateTimeFormat)}</div>
                <div class='content' v-html={msg.content}></div>
                {index < msgList.value.length - 1 ?
                  <a-divider style={{ margin: '10px 0' }} /> : null}
              </div>
            })}
          </> : <a-empty description='暂无数据' />}
        </a-spin>
      </div>
    )

    return () => (
      <div>
        <div class='news-wrapper'>
          <a-popover
            title='消息列表'
            trigger='click'
            onVisibleChange={onVisibleChange}
            arrowPointAtCenter={true}
            overlayStyle={{ width: '400px' }}
            content={messageContent}>
            <div onClick={handleMsgList}>
              <a-tooltip
                visible={!isLaster.value}
                overlayClassName='tip-wrap'
                getPopupContainer={(trigger => trigger.parentNode)}
                title={
                  <div class='tip-wrap' style={{ padding: '0 10px' }}>
                    <div>有新的任务消息，点击查看</div>
                    <div class='dot'></div>
                  </div>
                }>
                <a-badge dot={!isLaster.value}>
                  <BellOutlined />
                  <span class='text'>消息</span>
                </a-badge>
              </a-tooltip>
            </div>
          </a-popover>
        </div>
      </div>
    )
  },
})
