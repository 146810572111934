import { defineComponent } from 'vue'
// import { useRoute } from 'vue-router'
// import { message } from 'altihex-design'
// import { getToken, removeToken, goToSso } from '../../shared/auth'
// import { getGrap } from '../../shared/grapPolris'
// import ConfigInfo from '../../../config/index'

// interface Altihex {
//   run: (id: string) => void
// }
export default defineComponent({
  name: 'AltihexCore',
  setup() {
    // let altihex: Altihex
    // const route = useRoute()
    // const getId = () => {
    //   let splitRoute: Array<string> | string = route.fullPath.split('/')
    //   splitRoute = splitRoute[splitRoute.length - 1]
    //   splitRoute = splitRoute.split('?')[0]
    //   return splitRoute
    // }

    // const update = () => {
    //   const id = getId()
    //   id && altihex?.run(id)
    // }
    // onMounted(() => {
    //   const id = getId()
    //
    //   const headers = {
    //     accesstoken: getToken(),
    //     backstagecode: ConfigInfo.sysAclName,
    //     ...getGrap(),
    //   }
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   //@ts-ignore
    //   altihex = new window.AltihexSDK({
    //     root: '#_AltihexRoot_',
    //     cbLogin: () => {
    //       removeToken()
    //       goToSso()
    //     },
    //     headers,
    //   })
    //   if (id) {
    //     altihex?.run(id)
    //   } else {
    //     message.error('在天网平台中写入有效的id号')
    //   }
    // })
    return {
      // update,
    }
  },
  watch: {
    // $route() {
    //   this.update()
    // },
  },
  render() {
    return <div id='_AltihexRoot_'></div>
  },
})
