import { request } from '@request'
const prefix = '/zhaopin-outside/labour_company'

/**
 * 劳务公司-用工需求-消息列表
 * https://mooncake-v2.shizhuang-inc.com/project/interface?id=1166702&projectId=6039
 * @returns 
 */
export const getMsgList = () =>
  request({
    url: `${prefix}/labor_demand/msg/list`,
    method: 'get',
  })

/**
 * 劳务公司-用工需求-是否最新消息
 * https://mooncake-v2.shizhuang-inc.com/project/interface?id=1166701&projectId=6039
 * @returns 
 */
export const getIsLatestMsg = () =>
  request({
    url: `${prefix}/labor_demand/msg/latest`,
    method: 'get',
  })
